.post-login-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Creative Vintage Regular", serif;
    color: #29513e;
  }
  
  .post-login-container h1 {
    font-size: 48px;
    text-align: center;
    margin-bottom: 20px;
    background-color: #f2e3b8;
    padding: 20px;
    border-radius: 10px;
  }
  
  .post-login-container p {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    padding: 10px 20px;
    background-color: rgba(8, 73, 50, 0.8);
    border-radius: 10px;
  }
  
  
  
  
