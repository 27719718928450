body, html {
    height: 100%; /* ensure full viewport height */
    margin: 0;
    padding: 0;
    font-family: "Creative Vintage Regular", serif;
}

.login-container {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 80%;
    max-width: 400px;
    margin: auto; /* This centers it horizontally */
}

h2 {
    font-size: 36px;
    font-family: "Creative Vintage Regular", sans-serif;
}

.error {
    color: red;
    font-size: 16px;
    margin: 10px 0;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

label {
    font-size: 16px;
    margin-bottom: 5px;
}

input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    width: 100%;
}

button {
    padding: 10px 20px;
    background-color: #f0d09c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Creative Vintage Regular", serif;
}

button:hover {
    background-color: #e8c585;
}

