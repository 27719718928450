.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main-header {
    font-family: 'Creative Vintage Regular', sans-serif;
    font-size: 3rem;
    color: #3f3f3f;
    margin-bottom: 0.5em;
    background-color: rgba(240, 208, 156, 0.7);  /* Semi-transparent background for text */
    padding: 0.5em;
    border-radius: 5px;
}

.subtitle {
    font-family: 'Creative Vintage Regular', sans-serif;
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 2em;
    background-color: rgba(100, 124, 69, 0.7);  /* Semi-transparent background for text */
    padding: 0.5em;
    border-radius: 5px;
}

.button-group {
    display: flex;
    gap: 1rem;
}

.main-button {
    font-family: 'Creative Vintage Regular', sans-serif;
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: rgba(240, 208, 156, 0.8);  /* Semi-transparent background for buttons */
}

.main-button:hover {
    background-color: #e5ca8f;
}
