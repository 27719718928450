nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0d09c;
  height: 60px;
  padding: 0 20px;
  font-size: 24px;
  font-family: 'Creative Vintage Regular';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: rgb(5, 82, 74);
  transition: color 0.3s;
}

nav a:hover {
  color: #005e5e;
}



